<div class="patient-security">
	<div
		class="fx-layout-row-nowrap fx-content-start fx-items-start fx-gap-8"
		style="height: calc(100vh - 32px)">
		<div
			style="width: 260px"
			class="procedure-info fx-layout-column fx-gap-16">
			<!--        Patient identification-->
			@if (patientData) {
				<div class="info-card patient">
					<h4>{{ 'PATIENT_IDENTIFICATION' | translate }}</h4>
					<div class="list-row">
						<div class="list-row-key">
							{{ 'FULL_NAME' | translate }}
						</div>
						<div class="list-row-value">
							{{ patientData?.patientName }}
						</div>
					</div>
					<div class="list-row">
						<div class="list-row-key">
							{{ 'PATIENT_ID' | translate }}
						</div>
						<div class="list-row-value">
							{{ patientData?.patientID }}
						</div>
					</div>
					<div class="list-row">
						<div class="list-row-key">
							{{ 'GENDER' | translate }}
						</div>
						<div class="list-row-value">
							{{ patientData?.gender }}
						</div>
					</div>
					<div class="list-row">
						<div class="list-row-key">{{ 'AGE' | translate }}</div>
						<div class="list-row-value">
							{{ patientData?.patientAge }}
						</div>
					</div>
				</div>
			}

			<!--        About procedure-->
			<div class="info-card procedure">
				<h4>{{ 'RESOURCES' | translate }}</h4>
				<div class="list-row">
					<div class="list-row-key">{{ 'Bloc' | translate }}</div>
					<div class="list-row-value">
						<select name="">
							<option value="">Bloc 1</option>
							<option value="">Bloc 2</option>
						</select>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">{{ 'Salle' | translate }}</div>
					<div class="list-row-value">
						<select name="">
							<option value="">Salle 1</option>
							<option value="">Salle X1</option>
						</select>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						{{ 'Opérateur' | translate }}
					</div>
					<div class="list-row-value">
						<select name="">
							<option value="">Admin 1</option>
							<option value="">Opérateur 1</option>
						</select>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						{{ 'Technicien' | translate }}
					</div>
					<div class="list-row-value">
						<select name="">
							<option value="">Ali Amid</option>
							<option value="">Said Kali</option>
						</select>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">{{ 'Date' | translate }}</div>
					<div class="list-row-value">
						<input type="date" />
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">{{ 'Heure' | translate }}</div>
					<div class="list-row-value">
						<input type="time" name="" />
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						{{ 'Anesthésiste' | translate }}
					</div>
					<div class="list-row-value">
						<select name="">
							<option value="">John DOE</option>
							<option value="">Ahmed Said</option>
						</select>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">{{ 'Notes' | translate }}</div>
					<div class="list-row-value">
						<textarea name="" cols="15" rows="5"></textarea>
					</div>
				</div>
			</div>
		</div>

		<div
			class="security-checklist fx-grow-1 fx-layout-column fx-content-start fx-items-start fx-gap-12">
			<div
				class="info-card checklist-card before-procedure fx-layout-column-nowrap"
				style="background: #e4f6fc">
				<h4 style="width: 100%">Avant la procédure</h4>
				<div class="list-row">
					<div class="list-row-key">
						1. L'identité du patient est vérifiée
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						Adéquation du dossier (complet)
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						2. L'intervention et le site opératoire sont confirmés
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						3. La préparation cutanée est effectuée
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						4. Le consentement éclairé est tracé
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal">
						Autorisation d'itervention est signé par les parents ou
						le représentant légal
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key fit-content">
						5. Vérification croisée des points critiques et mise en
						oeuvre des mésures adéquates:
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ Le patient est à jeun:
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key fit-content normal">
						Le patient représente:
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd2">
						- un risque allergique:
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd2">
						- une insuffisance rénale:
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd2">
						- un rsique infectieux:
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd2">
						- un rsique lié à l'irradiation(grossesse, autre):
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ L’anticoagulation et/ou l’anti-agrégation est prise
						en compte
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key fit-content">
						6. L’équipement/matériel nécessaire pour l’intervention
						est vérifié et ne présente pas de dysfonctionnement
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd2">
						- Pour la partie anesthésique
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd2">
						- Pour la partie imagerie
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd2">
						- Pour la partie Interventionnelle
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>

				<div class="list-row">
					<div class="list-row-key">
						7. L’installation est conforme à la procédure à réaliser
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
			</div>
			<div
				class="info-card checklist-card before-procedure-start fx-layout-column-nowrap"
				style="background: #e4fcec">
				<h4 style="width: 100%">Avant le début de la procédure</h4>
				<div class="list-row">
					<div class="list-row-key fit-content">
						8. Vérification ultime croisée au sein de l’équipe en
						présence de l’opérateur et des paramédicaux
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ Identité du Patient
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ Intervention et site confirmés
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ Installation correcte
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ Documents nécessaires disponibles (imagerie,
						biologie)
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal">9. Antibioprophylaxie</div>
					<div class="list-row-value">
						<mat-checkbox>NR</mat-checkbox>
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal">
						10. La préparation du champ opératoire est réalisée
						selon le protocole en vigueur dans l’établissement
					</div>
					<div class="list-row-value">
						<mat-checkbox>NA</mat-checkbox>
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
			</div>
			<div
				class="info-card checklist-card child-patient fx-layout-column-nowrap"
				style="background: #fce4ec">
				<h4 style="width: 100%">
					Patient mineur ou sous régime de sauvegarde
				</h4>
				<div class="list-row">
					<div class="list-row-key fit-content">
						11. Les parents ont été associés aux vérifications :
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ Identité du Patient
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ intitulé d’intervention
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ site opératoire
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">♦ opérateur</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal">
						12. Autorisation d’opérer signée
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal">
						13. Installation, matériel et prescription adaptés au
						poids, à l’âge et à la taille. Prévention de
						l’hypothermie
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">
						♦ Seuils d’alerte en post-op définis.
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
			</div>
			<div
				class="info-card checklist-card child-patient fx-layout-column-nowrap"
				style="background: #fcf2e4">
				<h4 style="width: 100%">Après la procédure</h4>
				<div class="list-row">
					<div class="list-row-key">
						14 . Le matériel (D.M.) et les D.M.I. sont tracés
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						15 . Le compte final est correct :
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
						<mat-checkbox>NA</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">♦ compresses</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">♦ aiguilles</div>
				</div>
				<div class="list-row">
					<div class="list-row-key normal pd1">♦ instruments</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						16 . Les données de dosimétrie sont dans le Compte Rendu
						de l’examen
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						17 . La quantité et la nature du produit de contraste
						injecté sont précisées
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
						<mat-checkbox>NA</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						18 . Tout évènement indésirable est signalé
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
						<mat-checkbox>NA</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						19. L’archivage de l’examen est réalisé
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						20. Les prescriptions postinterventionnelles sont faites
						conjointement entre l’opérateur et l’anesthésiste
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
				<div class="list-row">
					<div class="list-row-key">
						21. Les transmissions sont effectuées
					</div>
					<div class="list-row-value">
						<mat-checkbox>Oui</mat-checkbox>
						<mat-checkbox>Non</mat-checkbox>
					</div>
				</div>
			</div>
			<div
				class="info-card checklist-card final-decision fx-layout-column-nowrap">
				<h4>Décision finale</h4>
				<div class="list-row">
					<mat-checkbox style="color: green; font-weight: bold"
						>GO = OK pour la procédure</mat-checkbox
					>
					<mat-checkbox style="color: red; font-weight: bold"
						>No GO = pas de procédure!</mat-checkbox
					>
				</div>
				<mat-divider></mat-divider>
				<div style="padding-left: 12px; font-size: 12px">
					Si NO GO: quelle conséquence sur l'intervention
				</div>
				<div class="list-row">
					<mat-checkbox>ANNULATION</mat-checkbox>
					<mat-checkbox>RETARD</mat-checkbox>
				</div>
				<mat-divider></mat-divider>
				<div
					class="fx-layout-row-nowrap fx-content-space-between"
					style="color: grey; padding: 0 12px">
					<span>NA = non applicable</span>
					<span>NR = non recommandé</span>
				</div>
			</div>
		</div>
	</div>
</div>
<div class="fx-layout-row-nowrap fx-content-end fx-items-end fx-gap-4">
	<button
		class="next-button"
		mat-raised-button
		color="primary"
		(click)="goToNext()">
		<mat-icon fontSet="mdi" fontIcon="mdi-arrow-right"></mat-icon>
		{{ 'NEXT' | translate }}
	</button>
</div>
