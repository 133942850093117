import { Component } from '@angular/core';
import { SharedService } from '../../shared';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
	selector: 'ft-pathology',
	templateUrl: './pathology.component.html',
	styleUrls: ['./pathology.component.scss'],
})
export class PathologyComponent {
	form: FormGroup;

	constructor(
		private service: SharedService,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<PathologyComponent>
	) {
		this.form = this.fb.group({
			id: '',
			value: [
				'',
				Validators.compose([
					Validators.required,
					Validators.minLength(4),
					Validators.maxLength(160),
				]),
			],
		});
	}

	savePathology(pathology) {
		this.service
			.savePathology(pathology)
			.subscribe(data => this.dialogRef.close(data));
	}
}
