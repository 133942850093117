export const R_DISPLAY_COLS = [
	{
		label: 'id',
		header: 'ID',
		value: 'id',
		sortField: 'id',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: true,
		searchable: true,
		required: false,
	},
	{
		label: 'patientID',
		header: 'PATIENT_ID',
		value: 'patientID',
		sortField: 'patient_id',
		type: 'string',
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: true,
		searchable: true,
		required: false,
	},
	{
		label: 'patientName',
		header: 'FULL_NAME',
		value: 'patientName',
		sortField: 'patient_name',
		type: 'string',
		unit: 'FULL_NAME',
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'performerNameId',
		header: 'RADIOLOGIST',
		value: 'performerNameId',
		sortField: 'performer_name_id',
		type: 'string',
		unit: 'physician',
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'completion',
		header: 'COMPLETIONDATE',
		value: 'expectedCompletionDateTime',
		sortField: 'expect_cmp_datetime',
		type: 'string',
		unit: 'date',
		defaultValue: [null],
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'priorityId',
		header: 'PRIORITY',
		value: 'priorityId',
		sortField: 'priority_fk',
		type: 'string',
		unit: 'prio',
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'scheduledProcedureStepStartDateTime',
		header: 'EXAM_DATE',
		value: 'scheduledProcedureStepStartDateTime',
		sortField: 'sps_datetime',
		type: 'string',
		unit: 'date',
		defaultValue: [null],
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'scheduledStationName',
		header: 'SCHEDULEDSTATION',
		value: 'scheduledStationName',
		sortField: 'scheduledStationName',
		type: 'string',
		unit: null,
		defaultValue: [null],
		sortable: false,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'pathology',
		header: 'PATHOLOGY',
		value: 'pathology',
		sortField: 'pathology',
		type: 'string',
		unit: 'pathology',
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'reportingStatus',
		header: 'REPORTING_STATUS',
		value: 'reportingStatus',
		sortField: 'reporting_status',
		type: 'status',
		unit: 'status',
		defaultValue: 'WAITING',
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'assigningComment',
		header: 'COMMENT',
		value: 'assigningComment',
		sortField: 'assigningComment',
		type: 'comment',
		unit: 'comment',
		defaultValue: '',
		sortable: false,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'paymentStatus',
		header: 'PAYMENT_STATUS',
		value: 'paymentStatus',
		sortField: 'payment_status',
		type: 'payment',
		unit: 'payment',
		defaultValue: 'NOT_PAID',
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'procedureCodes',
		header: 'EXAM',
		value: 'procedureCodes',
		sortField: 'procedure_codes',
		type: null,
		unit: null,
		defaultValue: '-',
		sortable: true,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'patientMedicalHistory',
		header: 'MEDICAL_HISTORY',
		value: 'patientMedicalHistory',
		sortField: 'patientMedicalHistory',
		type: null,
		unit: 'history',
		defaultValue: '-',
		sortable: false,
		hidden: false,
		searchable: true,
		required: false,
	},
	{
		label: 'labels',
		header: 'LABELS',
		value: 'labels',
		sortField: 'labels',
		type: null,
		unit: 'labels',
		defaultValue: '-',
		sortable: false,
		hidden: false,
		searchable: true,
		required: false,
	},
];

export const R_TABLE_COLS = [
	'scheduledProcedureStepStartDateTime',
	'patientName',
	'patientID',
	'procedureCodes',
	'performerNameId',
	'priorityId',
	'assigningComment',
	'patientMedicalHistory',
	'reportingStatus',
	'paymentStatus',
	'labels',
	'pathology',
	'action',
];

export const REPORT_STATUSES = [
	{ icon: 'mdi-file-outline', color: '#565f73', value: 'WAITING' },
	{ icon: 'mdi-file-clock', color: '#ea4335', value: 'IN_PROGRESS' },
	{
		icon: 'mdi-file-document-check',
		color: '#2147a8',
		value: 'TO_TRANSCRIBE',
	},
	{ icon: 'mdi-file-refresh', color: '#a85a16', value: 'TO_REVIEW' },
	{ icon: 'mdi-file-search', color: '#e62a5d', value: 'TO_VERIFY' },
	{ icon: 'mdi-file-sign', color: '#f33e2d', value: 'TO_SIGN' },
	{ icon: 'mdi-file-check-outline', color: '#34a853', value: 'FINISHED' },
	{ icon: 'mdi-file-star', color: '#4285f3', value: 'VERIFIED' },
	{ icon: 'mdi-file-check', color: '#ca1151', value: 'SIGNED' },
	{ icon: 'mdi-file-lock', color: '#36cd17', value: 'DELIVERED' },
];

export const PAYMENT_STATUSES = [
	{ icon: 'mdi-alpha-n-circle', color: '#f33e2d', value: 'NOT_PAID' },
	{
		icon: 'mdi-alpha-p-circle-outline',
		color: '#cb7604',
		value: 'PAID_PARTIALLY',
	},
	{ icon: 'mdi-alpha-p-circle', color: '#00a131', value: 'PAID' },
	{ icon: 'mdi-alpha-d-circle', color: '#534b60', value: 'EXEMPT' },
];
