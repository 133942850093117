import { BehaviorSubject, merge, of as observableOf } from 'rxjs';

import {
	catchError,
	debounceTime,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import {
	AfterViewInit,
	Component,
	ElementRef,
	OnInit,
	ViewChild,
} from '@angular/core';
import { ReportingService } from '../reporting.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { get } from 'lodash';
import {
	PAYMENT_STATUSES,
	R_DISPLAY_COLS,
	R_TABLE_COLS,
	REPORT_STATUSES,
} from './table-config';
import moment from 'moment';
import FileSaver from 'file-saver';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { LabelDTO, ReportFilter, ReportingTaskDTO } from '../../model';
import {
	CommentsComponent,
	DeleteConfirmComponent,
	LocalStorageService,
	MessageComponent,
	NormalReportComponent,
	PacsSearchComponent,
	PerformerAssignComponent,
	PrescriptionComponent,
	SharedService,
} from '../../shared';
import { ActivatedRoute, Router } from '@angular/router';
import { rowsAnimation } from '../../animations';
import { WsService } from '../../ws.service';
import { DateUtils } from '../../utils';
import { AppConfigService } from '../../app-config.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { GeneralSettingDTO } from '../../model';

@Component({
	selector: 'ft-reporting-home',
	templateUrl: './reporting-home.component.html',
	styleUrls: ['./reporting-home.component.scss'],
	animations: [rowsAnimation],
})
export class ReportingHomeComponent implements AfterViewInit, OnInit {
	displayedColumns = [];
	cols = [];

	userId: number;
	profile: any;
	canViewConfData: boolean;

	worklist: string = 'REPORTS_LIST';
	dataSource = new MatTableDataSource<ReportingTaskDTO>();
	resultsLength = 0;
	isLoadingResults = true;
	isDataLoaded = false;

	filterForm: FormGroup;
	menuChange = new BehaviorSubject('');

	@ViewChild('filter', { static: true }) filter: ElementRef;
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
	private newWindow: any;
	private reportFilterSubject = new BehaviorSubject<ReportFilter>(
		new ReportFilter()
	);
	private rf = new ReportFilter();
	currentDate = moment().format('LLLL');
	private query = new BehaviorSubject('');
	private username: string;

	sorting: { active: string; direction: 'asc' | 'desc' };
	dateFormat: string = 'DD/MM/YYYY';

	visibleLabels: any = {};
	reportStatuses: any[] = REPORT_STATUSES;

	private readonly datasets =
		'generalSetting,priorities,performingPhysicians';
	priorities: any[] = [];
	generalSetting: GeneralSettingDTO;

	paymentStatuses: any[] = PAYMENT_STATUSES;
	labels: LabelDTO[] = [];
	labelCtrl: FormControl = new FormControl<any>('');
	@ViewChild('labelInput') labelInput: ElementRef<HTMLInputElement>;
	filteredLabels: LabelDTO[] = [];
	priorityMap: any = {};
	physicians: any = {};

	constructor(
		private reportingService: ReportingService,
		private dialog: MatDialog,
		private fb: FormBuilder,
		private snack: MatSnackBar,
		private router: Router,
		private _config: AppConfigService,
		private route: ActivatedRoute,
		private wsService: WsService,
		private _shared: SharedService,
		private localStorage: LocalStorageService
	) {
		this.generalSetting = this._config.generalSetting;
		this.dateFormat = this._config.momentDateFormat;
		this.currentDate = moment().format(
			this._config.appLang === 'en' ? 'LL' : 'LLLL'
		);
		this.createForm();

		this._shared.getDatasets(this.datasets).subscribe(data => {
			this.datasets.split(',').forEach(it => (this[it] = data[it]));
			this.priorities.forEach(it => (this.priorityMap[it.id] = it.value));
			this['performingPhysicians'].forEach(
				it => (this.physicians[it.id] = it.fullName)
			);
		});

		this.sorting = this.localStorage.getItem('rt_sorting') || {
			active: 'expect_cmp_datetime',
			direction: 'desc',
		};

		this.labelCtrl.valueChanges
			.pipe(
				debounceTime(400),
				startWith(''),
				switchMap(() => {
					const query = this.labelCtrl.value;
					return this._shared.getLabels(
						10,
						0,
						'value',
						'asc',
						`${query}_`
					);
				}),
				map(data => data['content']),
				catchError(() => {
					return observableOf([]);
				})
			)
			.subscribe(data => (this.filteredLabels = data));
		this.labelCtrl.patchValue('');

		const user = get(this.route.snapshot.data, 'user');
		this.profile = user.profile;
		this.userId = user.id;
		this.username = user.username;
		this.canViewConfData = user.canViewConfidentialData;

		this.displayedColumns = R_DISPLAY_COLS;
		this.cols = R_TABLE_COLS;
		if (!this.generalSetting?.billingRequired)
			this.cols = this.cols.filter(col => col !== 'paymentStatus');

		this.menuChange.next('all');

		setTimeout(
			() =>
				this.wsService.observeTopic('reporting').subscribe(res => {
					if (
						res.topic === 'reporting' &&
						res.response === 'reportUpdate'
					)
						this.reportFilterSubject.next(this.rf);
				}),
			2000
		);
	}

	addComment(row) {
		this.dialog
			.open(CommentsComponent, {
				data: row.noteAlert,
				width: '400px',
				disableClose: true,
			})
			.afterClosed()
			.subscribe(comments => {
				if (comments && comments === 'dismiss') return;

				this.reportingService
					.saveNoteAlert(row.id, comments)
					.subscribe();
			});
	}

	changeRange(e) {
		let start = moment().format('YYYYMMDD');
		let end = start;

		switch (e.value) {
			case 'TODAY':
				this.rf.dateRange = `${start}-${end}`;
				this.setDateRange(start, end);
				break;
			case 'YESTERDAY':
				start = moment().subtract(1, 'd').format('YYYYMMDD');
				this.rf.dateRange = `${start}-${start}`;
				this.setDateRange(start, start);
				break;
			case '3D':
				start = moment().subtract(2, 'd').format('YYYYMMDD');
				this.rf.dateRange = `${start}-${end}`;
				this.setDateRange(start, end);
				break;
			case '1W':
				start = moment().subtract(6, 'd').format('YYYYMMDD');
				this.rf.dateRange = `${start}-${end}`;
				this.setDateRange(start, end);
				break;
			case '2W':
				start = moment().subtract(13, 'd').format('YYYYMMDD');
				this.rf.dateRange = `${start}-${end}`;
				this.setDateRange(start, end);
				break;
			case '1M':
				start = moment().subtract(1, 'month').format('YYYYMMDD');
				this.rf.dateRange = `${start}-${end}`;
				this.setDateRange(start, end);
				break;
			case '3M':
				start = moment().subtract(3, 'month').format('YYYYMMDD');
				this.rf.dateRange = `${start}-${end}`;
				this.setDateRange(start, end);
				break;
			case 'OT':
				start = moment().subtract(10, 'year').format('YYYYMMDD');
				end = moment().add(10, 'd').format('YYYYMMDD');
				this.rf.dateRange = `${start}-${end}`;
				this.setDateRange(null, null);
				break;
			default:
				this.rf.dateRange = `${start}-${end}`;
		}

		this.reportFilterSubject.next(this.rf);
	}

	private setDateRange(start, end) {
		const startDate = moment(start, 'YYYYMMDD');
		const endDate = moment(end, 'YYYYMMDD');
		this.filterForm.patchValue({ startDate, endDate });
	}

	getPaymentStatusColor(status: string): string {
		return (
			this.paymentStatuses.find(it => it.value === status)?.color ||
			'#ff1212'
		);
	}

	getPriorityColor(priority: string): string {
		return (
			this.priorities.find(it => it.value === priority)?.color ||
			'#a0a0a0'
		);
	}

	getPaymentStatusIcon(status: string): string {
		return (
			this.paymentStatuses.find(it => it.value === status)?.icon ||
			'mdi-alpha-n-circle'
		);
	}

	openReport(id: number, newWind?: boolean): void {
		if (newWind) {
			if (this.newWindow && !this.newWindow.closed) {
				this.newWindow.focus();
				this.newWindow.location.pathname = `/reporting/report-edition/${id}`;
			} else {
				this.newWindow = window.open(
					`/reporting/report-edition/${id}`,
					'_blank'
				);
				this.newWindow.addEventListener(
					'beforeunload',
					() => (this.newWindow = null)
				);
			}
		} else
			this.router
				.navigateByUrl(`/reporting/report-edition/${id}`)
				.then(console.log);
	}

	trackById(index: number, item: any): string {
		return item.id;
	}

	ngOnInit() {
		this.filter.nativeElement.focus();
	}

	showConfData(row, label): string {
		return !row.confidential || this.canViewConfData
			? row[label]
			: '**** ****';
	}

	deleteReportingTask(reportTask) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok) {
					this.reportingService
						.deleteReportingTask(reportTask.id)
						.subscribe(res => {
							this.reportFilterSubject.next(this.rf);
							this.snack.open(
								'La ligne selectionnée a été supprimée',
								'Ok',
								{ duration: 3000 }
							);
						});
				}
			});
	}

	printReportingTask(row) {
		this.reportingService.printReportingTask(row.id).subscribe(res => {
			const mediaType = 'application/pdf';
			const blob = new Blob([res], { type: mediaType });
			const filename =
				'Report_' + moment().format('YYYYMMDDHHmmss') + '.pdf';
			FileSaver.saveAs(blob, filename);
		});
	}

	createPrescription(row) {
		const patient = {
			patientName: row.patientName,
			patientID: row.patientID,
		};
		const physician = { physician: this.userId };
		this.dialog
			.open(PrescriptionComponent, {
				data: { patient, physician },
				width: '60%',
			})
			.afterClosed()
			.subscribe(res => console.log(res));
	}

	getStatus(col: any, row: any): string {
		switch (row['taskType']) {
			case 'Vérification':
				return row['taskStatus'] === 'Terminé'
					? 'Validé'
					: row[col.label];
			case 'Signature':
				return row['taskStatus'] === 'Terminé'
					? 'Signé'
					: row[col.label];
			default:
				return row[col.label];
		}
	}

	getStatusColor(status: string): string {
		return (
			this.reportStatuses.find(it => it.value === status)?.color ||
			'#989898'
		);
	}

	getStatusIcon(status: string): string {
		return (
			this.reportStatuses.find(it => it.value === status)?.icon ||
			'mdi-file'
		);
	}

	canPrint(row: any): boolean {
		const status = this.getStatus({ labal: 'taskStatus' }, row);
		return status === 'Validé' || status === 'Signé';
	}

	openDetails(row: ReportingTaskDTO) {
		if (this.userId === row.performerNameId) {
			this.dialog.open(PerformerAssignComponent, {
				data: { task: row, title: 'DELEGATE_TASK' },
				minWidth: '420px',
			});
		} else
			this.dialog.open(MessageComponent, {
				data: row,
				minWidth: '420px',
			});
	}

	getRowIcon(row: ReportingTaskDTO): string {
		if (this.userId === row.performerNameId) return 'mdi-account-multiple';
		else return 'mdi-comment-text-outline';
	}

	assignPerformer(row: ReportingTaskDTO, title: string = 'DELEGATE_TASK') {
		this.dialog.open(PerformerAssignComponent, {
			data: { task: row, title: title },
			minWidth: '380px',
		});
	}

	private resetPaginator() {
		this.filterForm.valueChanges.subscribe(
			() => (this.paginator.pageIndex = 0)
		);
	}

	private buildQueryParams = () =>
		this.reportFilterSubject.subscribe(value =>
			this.query.next(
				[
					value.key?.trim(),
					value.dateRange,
					value.labels,
					value.reportStatus,
					value.paymentStatus,
					value.priority,
				].join('@')
			)
		);

	formatDate(date: any): any {
		return moment(date, 'YYYY-MM-DD').format(this.dateFormat);
	}

	ngAfterViewInit() {
		this.buildQueryParams();
		this.resetPaginator();

		this.sort.sortChange.subscribe(res => {
			this.paginator.pageIndex = 0;
			this.localStorage.setItem('rt_sorting', res);
		});

		const filterObservables = [
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.menuChange.asObservable(),
			this.query.asObservable(),
		];

		merge(...filterObservables)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					const query = this.query.getValue();
					return this.reportingService.getReportTaskDTOs(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						'all',
						query
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isDataLoaded = false;
					this.resultsLength = data.totalElements;
					return data.content as ReportingTaskDTO[];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isDataLoaded = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	can(row: any, action: string): boolean {
		return (
			(this.profile[action] !== 'NONE' && !row.confidential) ||
			this.canViewConfData
		);
	}

	canAssign(row: any): boolean {
		return (
			(this.can(row, 'editReport') &&
				this.userId === row.performerNameId) ||
			this.profile['editReport'] === 'ALL'
		);
	}

	changePeriod() {
		this.filterForm.get('period').patchValue('OT');
	}

	private createForm() {
		this.filterForm = this.fb.group({
			key: '',
			labels: '',
			startDate: moment(),
			endDate: moment(),
			period: 'TODAY',
			reportStatus: null,
			priority: null,
			paymentStatus: null,
		});

		this.filterForm.valueChanges
			.pipe(debounceTime(300))
			.subscribe(value => this.buildReportFilter(value));
	}

	generateReport(row: any) {
		this.dialog.open(NormalReportComponent, {
			data: row.id,
			minHeight: '100vh',
		});
	}
	queryPacs = () =>
		this.dialog.open(PacsSearchComponent, {
			data: this.username,
			height: '100%',
			disableClose: true,
		});

	private buildReportFilter = (value: any) => {
		const startDate = moment(value.startDate).isValid()
			? moment(value.startDate)
			: moment().subtract(10, 'year');
		const endDate = moment(value.endDate).isValid()
			? moment(value.endDate)
			: moment().add(10, 'd');

		const start = startDate.format('YYYYMMDD');
		const end = endDate.format('YYYYMMDD');

		this.currentDate =
			start === end
				? startDate.format(
						this._config.appLang === 'en' ? 'LL' : 'LLLL'
					)
				: DateUtils.formatRange(
						startDate,
						endDate,
						this._config.appLang
					);

		this.rf.key = value.key;
		this.rf.dateRange = `${start}-${end}`;
		this.rf.labels = this.labels.map(it => it.id).join('__');
		this.rf.reportStatus =
			value.reportStatus && value.reportStatus.length !== 0
				? value.reportStatus.join('-')
				: 'ALL';
		this.rf.priority =
			value.priority && value.priority.length !== 0
				? value.priority.join('-')
				: 'ALL';
		this.rf.paymentStatus =
			value.paymentStatus && value.paymentStatus.length !== 0
				? value.paymentStatus.join('-')
				: 'ALL';

		this.reportFilterSubject.next(this.rf);
	};

	showLabels(id) {
		this.visibleLabels[id] = true;
	}

	add(event: MatChipInputEvent): void {
		const value = (event.value || '').trim();
		if (value && value != '') {
			const label = this.filteredLabels.find(it => it.value === value);
			if (label && !this.included(label)) this.labels.push(label);
		}
		event.chipInput!.clear();
		this.labelCtrl.setValue(null);
	}

	private included(label: LabelDTO): boolean {
		return this.labels.includes(label);
	}

	remove(label: LabelDTO): void {
		const index = this.labels.indexOf(label);
		if (index >= 0) this.labels.splice(index, 1);
		this.rf.labels = this.labels.map(it => it.id).join('__');
		this.reportFilterSubject.next(this.rf);
	}

	selected(event: MatAutocompleteSelectedEvent): void {
		const label = this.filteredLabels.find(
			it => it.value === event.option.viewValue
		);
		if (label && !this.included(label)) this.labels.push(label);
		this.labelInput.nativeElement.value = '';
		this.rf.labels = this.labels.map(it => it.id).join('__');
		this.reportFilterSubject.next(this.rf);

		this.labelCtrl.setValue(null);
	}
}
