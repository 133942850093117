import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientWorkflow, WorkflowItem } from '../../model';

@Component({
	selector: 'ft-patient-security',
	templateUrl: './patient-security.component.html',
	styleUrls: ['./patient-security.component.scss'],
})
export class PatientSecurityComponent {
	@Input() workflowItem: PatientWorkflow | WorkflowItem;
	@Input() patientData: any;
	@Output() nextEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
	patient = {};

	goToNext() {
		this.nextEvent.emit(true);
	}
}
