<div id="ft-player" class="fx-layout-column fx-content-space-between">
	<mat-toolbar class="dialog-toolbar">
		<mat-icon fontSet="mdi" fontIcon="mdi-disc-player"></mat-icon>
		<h3 style="padding-left: 6px">{{ 'AUDIO_PLAYER' | translate }}</h3>
		<span class="fx-grow-1"></span>
		<button mat-icon-button mat-dialog-close tabindex="-1">
			<mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
		</button>
	</mat-toolbar>

	<div class="fx-grow-1" mat-dialog-content style="overflow: visible">
		@if (audioSources.length) {
			<!--<div class="fx-grow-1" plyr style="width: 400px;"
                 [plyrTitle]="'Audio title'"
                 [plyrType]="'audio'"
                 [plyrSources]="audioSources"
                 [plyrOptions]="{invertTime: false, blankVideo: '#'}"
                 (plyrInit)="player = $event"></div>

            -->
		}
	</div>
</div>
